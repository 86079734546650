import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ClickOutsideDirective } from '@parashift/shared/directives';
import { FormsModule } from '@angular/forms';

export interface DropdownOptionSettings {
  /**
   * Main label of the option.
   */
  label: string;

  disabled?: boolean;

  /**
   * Alt text displayed as a small gray text on the right side of the option.
   */
  altText?: string;

  /**
   * Font awesome icon of the option.
   */
  icon?: IconName;

  /**
   * Function which is called when option is clicked.
   */
  click?: () => void;

  /**
   * Function which determines if option checkmark is visible.
   * @returns boolean indicating whether to show checkmark next to option.
   */
  isChecked?: () => boolean;
}

export interface DropdownOption {
  id: string;
  settings: DropdownOptionSettings;
}

export interface DropdownOptionGroup {
  /**
   * If true, the group won't appear in search results.
   */
  excludeInSearch?: boolean;

  label?: string;

  items: DropdownOptionSettings[];
}

@Component({
  selector: 'pp-dropdown',
  templateUrl: './pp-dropdown.component.html',
  styleUrls: ['./pp-dropdown.component.scss'],
  imports: [NgIf, NgFor, FaIconComponent, FormsModule, ClickOutsideDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParashiftDropdownComponent implements OnInit {

  @Input({ required: true })
  set options(value: DropdownOptionSettings[] | DropdownOptionGroup[]) {
    this._options = this.unpackInputValue(value);

    if (!this._allOptions) {
      // Save options set at init as "all options" for purpose of search feature.
      this._allOptions = this._options;
    }
  }
  get options(): DropdownOptionGroup[] { return this._options; }
  private _options: DropdownOptionGroup[];

  @Input() hasIcons = true;
  @Input() hasCheckmarks = true;

  @Input()
  @HostBinding('class.disable-chevron-animation')
  disableChevronAnimation = false;

  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  /**
   * Use this value to correctly position horizontally dropdown list relatively to dropdown toggle.
   */
  @Input()
  leftOffsetPx: number;

  /**
   * Use this value to correctly position vertically dropdown list relatively to dropdown toggle.
   */
  @Input()
  marginTopPx: number;

  /**
   * Defines minimum width of dropdown options list in pixels.
   */
  @Input()
  minWidthPx: number;
  
  /**
   * If true, displays sticky search input at the top and makes contents of dropdown searchable.
   */
  @Input()
  searchable = false;

  @Input()
  snapToRight = false;

  @ViewChild('searchInput')
  searchInput: ElementRef<HTMLInputElement>;

  opened = false;
  touched = false;

  hasAltText = false;

  get searchTerm() { return this._searchTerm; }
  set searchTerm(value) {
    this._searchTerm = value;

    if (!this._searchTerm) {
      this.options = this._allOptions;
      return;
    }

    const x = this._allOptions
      .filter(group => !group.excludeInSearch)
      .map(group => {
        if (this.matches(group.label, this._searchTerm)) {
          return group;
        }
        return {
          ...group,
          items: group.items.filter(item => this.matches(item.label, this._searchTerm))
        };
      })
      .filter(group => group.items.length > 0);

    this.options = x;
  }
  private _searchTerm: string;

  private _allOptions: DropdownOptionGroup[];

  // TODO (UX):
  // - add "ESC" keyboard shortcut in order to close dropdown (task size: small)
  // - add keyboard arrows navigation (task size: medium, needs implementing concept of "active" item in the dropdown)

  ngOnInit(): void {
    this.hasAltText = this.options.map(group => group.items).flat().some(option => !!option.altText);
  }

  toggle(): void {
    if (this.disabled) {
      return;
    }
    this.opened = !this.opened;
    this.touched = true;

    if (this.opened) {
      setTimeout(() => {
        if (this.searchInput) {
          this.searchInput.nativeElement.focus();
        }
      }, 200);
    }
  }

  close(): void {
    this.opened = false;

    this.resetSearchTermOnClose();
  }

  onOptionClick(option: DropdownOptionSettings): void {
    if (this.disabled || option.disabled) {
      return;
    }
    option.click();
    this.opened = false;
  }

  private resetSearchTermOnClose(): void {
    if (this.searchTerm) {
      setTimeout(() => {
        // setTimeout because I want to wait for closing animation to end before resetting searchTerm.
        // This allows avoiding weird flickering.
        this.searchTerm = undefined;
      }, 100);
    }
  }

  private matches(label: string, searchTerm: string): boolean {
    label = label.toLocaleLowerCase();
    searchTerm = searchTerm.toLocaleLowerCase();
    return label.includes(searchTerm);
  }

  private unpackInputValue(options: DropdownOptionSettings[] | DropdownOptionGroup[]): DropdownOptionGroup[] {
    if (options.length === 0) {
      return [];
    }

    if ((options[0] as DropdownOptionGroup).items) {
      return options as DropdownOptionGroup[];
    }

    return [{
      label: undefined,
      items: (options as DropdownOptionSettings[])
    }];
  }
}
