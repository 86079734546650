<div #dropdownToggle class="toggle-container" [class.open]="opened" (click)="toggle()">
  <fa-icon [icon]="['fas', 'chevron-down']" size="xs" class="zoom-percentage-arrow"></fa-icon>
  <ng-content></ng-content>
</div>

<div
  class="zoom-dropdown"
  [class.touched]="touched"
  [class.open]="opened"
  [class.right]="snapToRight"
  [class.no-icons]="!hasIcons"
  [class.no-checkmarks]="!hasCheckmarks"
  (clickOutside)="close()"
  [clickOutsideBlacklist]="[dropdownToggle]"
  [clickOutsideEvent]="'mousedown'"
  [style.left.px]="leftOffsetPx"
  [style.margin-top.px]="marginTopPx"
  [style.min-width.px]="minWidthPx">

  <div class="zoom-dropdown-triangle"></div>

  <div *ngIf="searchable" class="search-container">
    <input #searchInput type="text" spellcheck="false" [(ngModel)]="searchTerm" (focus)="searchInput.select()" />
    <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
  </div>

  <ng-container *ngIf="options.length === 0">
    <div class="no-options-container">Nothing to display.</div>
  </ng-container>

  <ng-container *ngFor="let group of options; let last = last;">
    <div *ngIf="group.label" class="zoom-dropdown-group-label">
      {{ group.label }}
    </div>
    <div *ngFor="let option of group.items" class="zoom-dropdown-option" [class.disabled]="option.disabled" (click)="onOptionClick(option)">
      <div *ngIf="hasCheckmarks" class="zoom-dropdown-checkmark">
        <fa-icon *ngIf="option.isChecked?.()" [icon]="['fas', 'check']" [fixedWidth]="true"></fa-icon>
      </div>

      <div *ngIf="hasIcons && option.icon" class="zoom-dropdown-icon">
        <fa-icon [icon]="['fas', option.icon]"></fa-icon>
      </div>

      <div class="zoom-dropdown-label">
        {{ option.label }}
      </div>

      <div *ngIf="hasAltText" class="zoom-dropdown-label-alt text">
        {{ option.altText }}
      </div>
    </div>

    <div *ngIf="!last" class="zoom-dropdown-separator"></div>
  </ng-container>

</div>
